import { Formik, Field, ErrorMessage, FieldProps } from "formik";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import React from "react";
import MlxButton from "../../../components/common/MlxButton";

type Props = {
  sidebarVisible: boolean;
  setSidebarVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddOrganizationSidebar = ({
  sidebarVisible,
  setSidebarVisible,
}: Props) => {
  const customHeader = <h3>Add Organization</h3>;

  return (
    <Sidebar
      className="sidebar-form"
      header={customHeader}
      visible={sidebarVisible}
      position="right"
      onHide={() => setSidebarVisible(false)}
    >
      <Formik
        initialValues={{
          organizationName: "",
          contactName: "",
          email: "",
          primaryPhone: "",
          secondaryPhone: "",
          contactAddress: "",
          city: "",
          state: "",
          country: "",
          zip: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 1000);
        }}
      >
        {({ isSubmitting }) => (
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-field">
              <Field name="organizationName">
                {({ field, form }: FieldProps) => (
                  <>
                    <label htmlFor="org-name-input">Organization Name</label>
                    <InputText
                      id="org-name-input"
                      value={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="organizationName"
                      component="div"
                      className="error"
                    />
                  </>
                )}
              </Field>
            </div>
            <div className="form-field">
              <Field name="contactName">
                {({ field, form }: FieldProps) => (
                  <>
                    <label htmlFor="contact-name-input">Contact Name</label>
                    <InputText
                      id="contact-name-input"
                      value={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="contactName"
                      component="div"
                      className="error"
                    />
                  </>
                )}
              </Field>
            </div>
            <div className="form-field">
            <Field name="email" type="email">
                {({ field, form }: FieldProps) => (
                  <>
                    <label htmlFor="email-input">Email</label>
                    <InputText
                      id="email-input"
                      value={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </>
                )}
              </Field>
            </div>
            <div className="d-flex justify-content-between">
              <div className="form-field">
              <Field name="primaryPhone">
                {({ field, form }: FieldProps) => (
                  <>
                    <label htmlFor="primary-phone-input">Primary Phone</label>
                    <InputText
                      id="primary-phone-input"
                      value={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="primaryPhone"
                      component="div"
                      className="error"
                    />
                  </>
                )}
              </Field>
              </div>
              <div className="form-field">
              <Field name="secondaryPhone">
                {({ field, form }: FieldProps) => (
                  <>
                    <label htmlFor="secondary-phone-input">Secondary Phone</label>
                    <InputText
                      id="secondary-phone-input"
                      value={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="secondaryPhone"
                      component="div"
                      className="error"
                    />
                  </>
                )}
              </Field>
              </div>
            </div>
            <div className="form-field">
            <Field name="contactAddress">
                {({ field, form }: FieldProps) => (
                  <>
                    <label htmlFor="contact-address-input">Contact Address</label>
                    <InputText
                      id="contact-address-input"
                      value={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="contactAddress"
                      component="div"
                      className="error"
                    />
                  </>
                )}
              </Field>
            </div>
            <div className="d-flex justify-content-between justify-content-between">
              <div className="form-field">
              <Field name="zip">
                {({ field, form }: FieldProps) => (
                  <>
                    <label htmlFor="zip-input">Zip</label>
                    <InputText
                      id="zip-input"
                      value={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="zip"
                      component="div"
                      className="error"
                    />
                  </>
                )}
              </Field>
              </div>
              <div className="form-field">
              <Field name="city">
                {({ field, form }: FieldProps) => (
                  <>
                    <label htmlFor="city-input">City</label>
                    <InputText
                      id="city-input"
                      value={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="error"
                    />
                  </>
                )}
              </Field>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="form-field">
              <Field name="state">
                {({ field, form }: FieldProps) => (
                  <>
                    <label htmlFor="state-input">State</label>
                    <InputText
                      id="state-input"
                      value={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="state"
                      component="div"
                      className="error"
                    />
                  </>
                )}
              </Field>
              </div>
              <div className="form-field">
              <Field name="country">
                {({ field, form }: FieldProps) => (
                  <>
                    <label htmlFor="country-input">Country</label>
                    <InputText
                      id="country-input"
                      value={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="country"
                      component="div"
                      className="error"
                    />
                  </>
                )}
              </Field>
              </div>
            </div>
            <div className="form-field">
            <Field name="website">
                {({ field, form }: FieldProps) => (
                  <>
                    <label htmlFor="website-input">Website</label>
                    <InputText
                      id="website-input"
                      value={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="website"
                      component="div"
                      className="error"
                    />
                  </>
                )}
              </Field>
            </div>
          </form>
        )}
      </Formik>
      <div className="step2-action-container d-flex justify-content-end">
        <MlxButton label="Cancel" onClick={() => setSidebarVisible(false)} />
        <MlxButton className="continue-btn" label="Add Organization" type="submit" />
      </div>
    </Sidebar>
  );
};

export default AddOrganizationSidebar;
