import "./header.css";
import { OverlayPanel } from "primereact/overlaypanel";
import micrologx_logo from "../../assets/images/micrologx_logo.png";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

function HeaderComponent() {
  const op = useRef<any>(null);
  const navigate = useNavigate();
  const {user} = useAuth()
  return (
    <div
      className="header-container d-flex justify-content-between"
      onClick={(e) => op?.current?.toggle(e)}
    >
      <OverlayPanel ref={op}>
        <div>My Profile</div>
        <hr />
        <div className="logout-btn" onClick={()=>navigate("/login")}>Logout</div>
      </OverlayPanel>
      <img src={micrologx_logo} alt="logo" />
      <div className="profile-section d-flex">
        <div className="profile-img"></div>
        <div className="profile-name-role d-flex flex-column">
          <div className="user-name">John Doe</div>
          <div className="user-role">{user?.userRole==="mlx-admin" ? "Super Admin" : "Org Admin"}</div>
        </div>
      </div>
    </div>
  );
}

export default HeaderComponent;
