import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import bgImage from "../../../assets/images/login-bg.jpg";
import { ScrollTop } from "primereact/scrolltop";
import { useNavigate } from "react-router-dom";
import MlxButton from "../../../components/common/MlxButton";
import "../styles/getStarted.css";

type Props = {
  isGetStarted?: boolean;
  onContinue?: Function;
};

function Step2({ onContinue, isGetStarted }: Props) {
  const [venueName, setVenueName] = useState<string>("");
  const [venueURL, setVenueURL] = useState<string>("");
  const navigate = useNavigate();

  const continueClicked = () => {
    if (isGetStarted) {
      if (onContinue) onContinue("step3");
    }
    console.log("continue clicked");
  };

  const skipToDashboard = () => {
    console.log("to dashboard");
    navigate("/dashboard");
  };

  return (
    <div className="login-page-container d-flex flex-row">
      <img src={bgImage} style={{ width: "50%", height: "100vh" }} alt="" />
      <div
        style={{
          flexGrow: "2",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: "125px",
          marginRight: "85px",
        }}
      >
        <div className="step-number">Step 2 of 4</div>
        <div className="step-title">Let's create your first Venue</div>
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </div>
        <div className="step2-form-container">
          <div className="p-field">
            <label htmlFor="venue-name" style={{ fontWeight: "normal" }}>
              What is the name of the venue
            </label>
            <InputText
              id="venue-name"
              className="venue-name-input"
              value={venueName}
              onChange={(e) => setVenueName(e.target.value)}
            />
          </div>
          <div className="venue-url-container">
            <div className="venue-url-warning"><i className="pi pi-info-circle"/> Once you set the URL name, it cannot be changed.</div>
            <div className="p-field">
                <label htmlFor="venue-url">Prefered venue URL</label>
                <InputText
                id="venue-url"
                value={venueURL}
                onChange={(e) => setVenueURL(e.target.value)}
                style={{width:"470px"}}
                />
                <span style={{color:"#827c81"}}> .micrologx.app</span>
            </div>
          </div>
          <div className="step2-action-container d-flex justify-content-end">
            <MlxButton label="Skip" onClick={skipToDashboard} />
            <MlxButton className="continue-btn" label="Continue" onClick={continueClicked} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step2;
