import "../styles/getStarted.css";
import { Checkbox } from "primereact/checkbox";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { InputText } from "primereact/inputtext";
import { ScrollTop } from "primereact/scrolltop";
import { useNavigate } from "react-router-dom";
import bgImage from "../../../assets/images/login-bg.jpg";
import MlxButton from "../../../components/common/MlxButton";
import React, { useState } from "react";

type Props = {
  isGetStarted?: boolean;
  onContinue?: Function;
};

function Step4({ onContinue, isGetStarted }: Props) {
  const navigate = useNavigate();

  const finishClicked = () => {
    navigate("/dashboard");
  };

  const backToStep3 = () => {
    if (isGetStarted) {
      if (onContinue) onContinue("step3");
    }
  };

  return (
    <div className="login-page-container d-flex flex-row">
      <img src={bgImage} style={{ width: "50%", height: "100vh" }} alt="" />
      <div
        style={{
          flexGrow: "2",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: "125px",
          marginRight: "85px",
        }}
      >
        <div className="step-number">Step 4 of 4</div>
        <div className="step-title">Let's create your first Venue</div>
        <div>Please add your branding details here</div>
        <div className="step3-form-container">
          <div className="step2-action-container d-flex justify-content-end">
            <MlxButton label="Back" onClick={backToStep3} />
            <MlxButton
              className="continue-btn"
              label="Finish"
              type="submit"
              onClick={finishClicked}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step4;
