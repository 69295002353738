import React, { createContext, useContext, useState } from 'react';

// Define types for your authentication context
interface User {
  userRole: string;
}

interface AuthContextType {
  user: User | null;
  isLoggedIn: boolean;
  login: (userData: User) => void;
  logout: () => void;
}

// Create the AuthContext
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a custom hook to access the AuthContext
export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

// AuthProvider component to wrap your application
export const AuthProvider = ({ children } : any) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const login = (userData: User) => {
    // Logic for handling login
    setUser(userData);
    setIsLoggedIn(true);
  };

  const logout = () => {
    // Logic for handling logout
    setUser(null);
    setIsLoggedIn(false);
  };

  // Value object that contains all the context data and functions
  const value: AuthContextType = {
    user,
    isLoggedIn,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};