import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import MlxButton from "../../components/common/MlxButton";
import { Checkbox } from "primereact/checkbox";
import bgImage from "../../assets/images/login-bg.jpg";
import { useNavigate } from "react-router-dom";
import micrologx_logo from "../../assets/images/micrologx_logo.png";
import Login from "../login/Login";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";

const GetStarted = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<string>("get-started");

  const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setCurrentStep("login");
  };

  return (
    <>
      {currentStep === "get-started" && (
        <>
          <div className="login-page-container d-flex flex-row">
            <img src={bgImage} style={{ width: "50%", height: "100vh" }} />
            <div
              style={{
                flexGrow: "1",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="" style={{ width: "280px" }}>
                <div className="">
                  <img src={micrologx_logo} alt="logo" />
                </div>
                <div className="p-card-body">
                  <form onSubmit={handleLogin}>
                    <div className="p-field">
                      <label htmlFor="username">Email</label>
                      <InputText
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="p-field">
                      <label htmlFor="password">Password</label>
                      <InputText
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="p-field">
                      <label htmlFor="confirm-password">Confirm Password</label>
                      <InputText
                        id="comfirm-password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <MlxButton type="submit" label="Get Started" className="" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {currentStep === "login" && <Login onLogin={()=>setCurrentStep("step2")} isGetStarted={true}/>}
      {currentStep === "step2" && <Step2 isGetStarted={true} onContinue={(res:string)=>setCurrentStep(res)}/>}
      {currentStep === "step3" && <Step3 isGetStarted={true} onContinue={(res:string)=>setCurrentStep(res)}/>}
      {currentStep === "step4" && <Step4 isGetStarted={true} onContinue={(res:string)=>setCurrentStep(res)}/>}
    </>
  );
};

export default GetStarted;
