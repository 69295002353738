import "../styles/getStarted.css";
import { Checkbox } from "primereact/checkbox";
import { Formik, Form, Field, ErrorMessage, FieldProps } from "formik";
import bgImage from "../../../assets/images/login-bg.jpg";
import MlxButton from "../../../components/common/MlxButton";
import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

type Props = {
  isGetStarted?: boolean;
  onContinue?: Function;
};

function Step3({ onContinue, isGetStarted }: Props) {
  const [selectedCity, setSelectedCity] = useState(null);
  const countryList = [
    { name: "United States of America" },
    { name: "France" },
    { name: "Spain" },
    { name: "Germany" },
  ];

  const continueClicked = () => {
    if (isGetStarted) {
      if (onContinue) onContinue("step4");
    }
  };

  const backToStep2 = () => {
    if (isGetStarted) {
        if (onContinue) onContinue("step2");
    }
  };

  return (
    <div className="login-page-container d-flex flex-row">
      <img src={bgImage} style={{ width: "50%", height: "100vh" }} alt="" />
      <div
        style={{
          flexGrow: "2",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "40px",
          marginLeft: "125px",
          marginRight: "85px",
        }}
      >
        <div className="step-number">Step 3 of 4</div>
        <div className="step-title">Let's create your first Venue</div>
        <div style={{ marginBottom: "20px" }}>Please add your tenant contact details here</div>
        <div className="step3-form-container">
          <Formik
            initialValues={{
              contactName: "",
              email: "",
              timeZone: "",
              primaryPhone: "",
              secondaryPhone: "",
              contactAddress: "",
              zip: "",
              city: "",
              state: "",
              country: "United States of America",
            }}
            onSubmit={(values) => {
              // Handle form submission here
              console.log(values);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-field">
                  <Field name="contactName">
                    {({ field, form }: FieldProps) => (
                      <>
                        <label htmlFor="contact-name-input">Contact Name</label>
                        <InputText
                          id="contact-name-input"
                          value={field.value}
                          onChange={(e) =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                        <ErrorMessage
                          name="contactName"
                          component="div"
                          className="error"
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="form-field">
                    <Field name="email">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="email-input">Email</label>
                          <InputText
                            id="email-input"
                            value={field.value}
                            style={{ width: "260px" }}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="form-field">
                    <Field name="timeZone">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="time-zone-input">Time Zone</label>
                          <InputText
                            id="time-zone-input"
                            value={field.value}
                            style={{ width: "260px" }}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="timeZone"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="form-field">
                    <Field name="primaryPhone">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="primary-phone-input">
                            Primary Phone
                          </label>
                          <InputText
                            id="primary-phone-input"
                            value={field.value}
                            style={{ width: "260px" }}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="primaryPhone"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="form-field">
                    <Field name="secondaryPhone">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="secondary-phone-input">
                            Secondary Phone
                          </label>
                          <InputText
                            id="primary-phone-input"
                            value={field.value}
                            style={{ width: "260px" }}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="secondaryPhone"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="form-field">
                  <Field name="contactAddress">
                    {({ field, form }: FieldProps) => (
                      <>
                        <label htmlFor="contact-address-input">
                          Contact Address
                        </label>
                        <InputText
                          id="contact-address-input"
                          value={field.value}
                          onChange={(e) =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                        <ErrorMessage
                          name="contactAddress"
                          component="div"
                          className="error"
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="form-field">
                    <Field name="zip">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="zip-input">Zip</label>
                          <InputText
                            id="zip-input"
                            value={field.value}
                            style={{ width: "260px" }}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="zip"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="form-field">
                    <Field name="city">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="city-input">City</label>
                          <InputText
                            id="city-input"
                            value={field.value}
                            style={{ width: "260px" }}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="form-field">
                    <Field name="state">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="state-input">State</label>
                          <InputText
                            id="state-input"
                            value={field.value}
                            style={{ width: "260px" }}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="form-field">
                    <Field name="country">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="country-input">Country</label>
                          <Dropdown
                            value={selectedCity}
                            onChange={(e) => {form.setFieldValue(field.name, e.target.value); setSelectedCity(e.value)}}
                            options={countryList}
                            optionLabel="name"
                            placeholder="Select a Country"
                            className="country-input"
                            style={{ width: "260px", height: "45px" }}
                          />
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="step2-action-container d-flex justify-content-end">
                  <MlxButton label="Back" onClick={backToStep2}/>
                  <MlxButton className="continue-btn" label="Continue" type="submit" onClick={continueClicked}/>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Step3;
