import { Formik, Field, ErrorMessage, Form, FieldProps } from "formik";
import { Sidebar } from "primereact/sidebar";
import React, { useState } from "react";
import MlxButton from "../../../../components/common/MlxButton";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

type Props = {
  sidebarVisible: boolean;
  setSidebarVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddVenueSidebar = ({ sidebarVisible, setSidebarVisible }: Props) => {

  const customHeader = <h3>Add Venue</h3>;
  const [selectedCity, setSelectedCity] = useState(null);
  const countryList = [
    { name: "United States of America" },
    { name: "France" },
    { name: "Spain" },
    { name: "Germany" },
  ];

  return (
    <Sidebar
      className="sidebar-form"
      header={customHeader}
      visible={sidebarVisible}
      position="right"
      onHide={() => setSidebarVisible(false)}
    >
      <TabView>
        <TabPanel header="General">
          <Formik
            initialValues={{
              contactName: "",
              email: "",
              timeZone: "",
              primaryPhone: "",
              secondaryPhone: "",
              contactAddress: "",
              zip: "",
              city: "",
              state: "",
              country: "United States of America",
            }}
            onSubmit={(values) => {
              // Handle form submission here
              console.log(values);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-field">
                  <Field name="contactName">
                    {({ field, form }: FieldProps) => (
                      <>
                        <label htmlFor="contact-name-input">Contact Name</label>
                        <InputText
                          id="contact-name-input"
                          value={field.value}
                          onChange={(e) =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                        <ErrorMessage
                          name="contactName"
                          component="div"
                          className="error"
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="form-field">
                    <Field name="email">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="email-input">Email</label>
                          <InputText
                            id="email-input"
                            value={field.value}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="form-field">
                    <Field name="timeZone">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="time-zone-input">Time Zone</label>
                          <InputText
                            id="time-zone-input"
                            value={field.value}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="timeZone"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="form-field">
                    <Field name="primaryPhone">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="primary-phone-input">
                            Primary Phone
                          </label>
                          <InputText
                            id="primary-phone-input"
                            value={field.value}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="primaryPhone"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="form-field">
                    <Field name="secondaryPhone">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="secondary-phone-input">
                            Secondary Phone
                          </label>
                          <InputText
                            id="primary-phone-input"
                            value={field.value}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="secondaryPhone"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="form-field">
                  <Field name="contactAddress">
                    {({ field, form }: FieldProps) => (
                      <>
                        <label htmlFor="contact-address-input">
                          Contact Address
                        </label>
                        <InputText
                          id="contact-address-input"
                          value={field.value}
                          onChange={(e) =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                        <ErrorMessage
                          name="contactAddress"
                          component="div"
                          className="error"
                        />
                      </>
                    )}
                  </Field>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="form-field">
                    <Field name="zip">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="zip-input">Zip</label>
                          <InputText
                            id="zip-input"
                            value={field.value}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="zip"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="form-field">
                    <Field name="city">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="city-input">City</label>
                          <InputText
                            id="city-input"
                            value={field.value}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="form-field">
                    <Field name="state">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="state-input">State</label>
                          <InputText
                            id="state-input"
                            value={field.value}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="form-field">
                    <Field name="country">
                      {({ field, form }: FieldProps) => (
                        <>
                          <label htmlFor="country-input">State</label>
                          <Dropdown
                            value={selectedCity}
                            onChange={(e) => {form.setFieldValue(field.name, e.target.value); setSelectedCity(e.value)}}
                            options={countryList}
                            optionLabel="name"
                            placeholder="Select a City"
                            className="country-input"
                            style={{width: "248px", height: "45px"}}
                          />
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="error"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </TabPanel>
        <TabPanel header="Branding"></TabPanel>
      </TabView>
      <div className="step2-action-container d-flex justify-content-end">
        <MlxButton label="Cancel" onClick={() => setSidebarVisible(false)} />
        <MlxButton className="continue-btn" label="Continue" type="submit" />
      </div>
    </Sidebar>
  );
};

export default AddVenueSidebar;
