import React, { useState } from "react";
import withSidebar from "../../../hoc/withSideBar";
import comingSoon from '../../../assets/images/coming-soon.png'

function AnalyticsReporting() {

  return (
    <>
      <div className="page-title">Analytics and Report</div>
      <div className="d-flex justify-content-center">
        <img
          style={{ height: "500px", width: "500px" }}
          src={comingSoon}
          alt=""
        />
      </div>
    </>
  );
}

export default withSidebar(AnalyticsReporting);
