import React from "react";
import "./App.css";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import OrgManagement from "./pages/org_management/OrgManagement";
import VenueManagement from "./pages/venue_management/VenueManagement";
import GetStarted from "./pages/get_started/GetStarted";
import { AuthProvider } from "./context/AuthContext";
import OrgDashboard from "./pages/org admin pages/dashboard/OrgDashboard";
import OrgVenueManagement from "./pages/org admin pages/org_venue_management/OrgVenueManagement";
import AnalyticsReporting from "./pages/org admin pages/analytics_reporting/AnalyticsReporting";

function App() {
  return (
    <div className="App">
      <PrimeReactProvider>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/manage-organization" element={<OrgManagement />} />
            <Route path="/manage-venue" element={<VenueManagement />} />
            <Route path="/get-started" element={<GetStarted />} />
            <Route path="/org-admin/dashboard" element={<OrgDashboard />} />
            <Route path="/org-admin/manage-venue" element={<OrgVenueManagement />} />
            <Route path="/org-admin/analytics-reporting" element={<AnalyticsReporting />} />
          </Routes>
        </AuthProvider>
      </PrimeReactProvider>
    </div>
  );
}

export default App;
