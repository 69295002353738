import React, { useState } from "react";
import withSidebar from "../../hoc/withSideBar";
import MlxInputSwitch from "../../components/common/MlxInputSwitch";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import MlxButton from "../../components/common/MlxButton";

function VenueManagement() {
  const [tableData, setTableData] = useState([
    {
      Venue: "Garibaldi",
      Organization: "Micrologx",
      Location: "Fort Worth, Texas",
      URL: "garibaldi-fuquay.micrologx.app",
      Status: "Active",
    },
    {
      Venue: "Garibaldi 2",
      Organization: "Micrologx",
      Location: "Fort Worth, Texas",
      URL: "garibaldi-texas.micrologx.app",
      Status: "Active",
    },
    {
      Venue: "Garibaldi 3",
      Organization: "Micrologx",
      Location: "Fort Worth, Texas",
      URL: "test.micrologx.app",
      Status: "Active",
    },
    {
      Venue: "Venue 1",
      Organization: "Organizationl",
      Location: "Fort Worth, Texas",
      URL: "test.micrologx.app",
      Status: "Active",
    },
    {
      Venue: "Venue 2",
      Organization: "Organization2",
      Location: "Fort Worth, Texas",
      URL: "test.micrologx.app",
      Status: "Active",
    },
  ]);

  const statusTemplate = (tempData: any) => {
    return (
      <>
        {tempData.Status}
        <MlxInputSwitch checked={true} />
      </>
    );
  };

  const editButton = (tempData: any) => {
    return <i className="pi pi-pencil" />
  };

  return (
    <>
      <div className="page-title">Manage Venue</div>
      <div className="org-table-container">
        <div className="org-table-header d-flex justify-content-between">
          <div>Venue List</div>
          <div>
            <MlxButton style={{ height:"45px", marginRight:"10px" }} label="Add Venue +"></MlxButton>
            <InputText placeholder="Search" />
          </div>
        </div>
        <DataTable className="org-mngmt-table" value={tableData}>
          <Column field="Venue" header="Venue"></Column>
          <Column field="Organization" header="Organization"></Column>
          <Column field="Location" header="Location"></Column>
          <Column field="URL" header="URL"></Column>
          <Column field="Status" header="Status" body={statusTemplate}></Column>
          <Column field="edit" body={() => <i className="pi pi-pencil edit-row" onClick={() => console.log("edit clicked")} />}></Column>
          <Column field="delete" body={() => <i className="pi pi-trash delete-row" onClick={() => console.log("delete clicked")} />}></Column>
        </DataTable>
      </div>
    </>
  );
}

export default withSidebar(VenueManagement);
