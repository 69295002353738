import React, { useState } from "react";
import withSidebar from "../../hoc/withSideBar";
import MlxButton from "../../components/common/MlxButton";
import MlxInputSwitch from "../../components/common/MlxInputSwitch";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./styles/orgManagement.css";
import AddOrganizationSidebar from "./components/AddOrganizationSidebar";

function OrgManagement() {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const [tableData, setTableData] = useState([
    {
      "Organization Name": "Garibaldi",
      Email: "garibaldi@gmail.com",
      Phone: "+1-202-555-0176",
      "Venue Count": 5,
      Website: "80202",
      "Registration Date": "10/15/19",
      Status: "Active",
    },
    {
      "Organization Name": "Organization l",
      Email: "tenantl@gmail.com",
      Phone: "+1-202-555-0176",
      "Venue Count": 1,
      Website: "80202",
      "Registration Date": "10/15/19",
      Status: "Inactive",
    },
    {
      "Organization Name": "Organization 2",
      Email: "tenant2@gmail.com",
      Phone: "+1-202-555-0176",
      "Venue Count": 10,
      Website: "80202",
      "Registration Date": "10/15/19",
      Status: "Active",
    },
    {
      "Organization Name": "Organization 3",
      Email: "tenant3@gmail.com",
      Phone: "+1-202-555-0176",
      "Venue Count": 5,
      Website: "80202",
      "Registration Date": "10/15/19",
      Status: "Active",
    },
    {
      "Organization Name": "Organization 4",
      Email: "tenant4@gmail.com",
      Phone: "+1-202-555-0176",
      "Venue Count": 1,
      Website: "80202",
      "Registration Date": "10/15/19",
      Status: "Active",
    },
  ]);

  const statusTemplate = (tempData: any) => {
    return (
      <>
        {tempData.Status}
        <MlxInputSwitch checked={true} />
      </>
    );
  };

  return (
    <>
      <AddOrganizationSidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible}/>
      <div className="page-title">Manage Organization</div>
      <div className="org-table-container">
        <div className="org-table-header d-flex justify-content-between">
          <div>Organization List</div>
          <div>
            <MlxButton label="Add Organization +" style={{ height:"45px", marginRight:"10px" }} onClick={()=>setSidebarVisible(true)}></MlxButton>
            <InputText placeholder="Search" />
          </div>
        </div>
        <DataTable className="org-mngmt-table" value={tableData}>
          <Column field="Organization Name" header="Organization Name"></Column>
          <Column field="Email" header="Email"></Column>
          <Column field="Phone" header="Phone"></Column>
          <Column field="Venue Count" header="Venue Count"></Column>
          <Column field="Website" header="Website"></Column>
          <Column field="Registration Date" header="Registration Date"></Column>
          <Column field="Status" header="Status" body={statusTemplate}></Column>
          <Column field="edit" body={() => <i className="pi pi-pencil edit-row" onClick={() => console.log("edit clicked")} />}></Column>
          <Column field="delete" body={() => <i className="pi pi-trash delete-row" onClick={() => console.log("delete clicked")} />}></Column>
        </DataTable>
      </div>
    </>
  );
}

export default withSidebar(OrgManagement);
