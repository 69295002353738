import React from 'react'
import { InputSwitch, InputSwitchProps } from 'primereact/inputswitch'

const MlxInputSwitch: React.FC<InputSwitchProps> = (props: InputSwitchProps) => {

    return (
        <InputSwitch className='testName' {...props}></InputSwitch>
    )
}

export default MlxInputSwitch