import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import MlxButton from "../../components/common/MlxButton";
import { Checkbox } from "primereact/checkbox";
import bgImage from "../../assets/images/login-bg.jpg";
import "./login.css";
import { ScrollTop } from "primereact/scrolltop";
import { useNavigate } from "react-router-dom";
import micrologx_logo from "../../assets/images/micrologx_logo.png";
import { useAuth } from "../../context/AuthContext";

type Props = {
  isGetStarted?: boolean;
  onLogin?: Function;
};

const Login = ({ onLogin, isGetStarted }: Props) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Implement login logic here
    console.log("username:", username);
    console.log("password:", password);
    console.log("rememberMe:", rememberMe);
    if (username === "org-admin") {
      login({ userRole: "org-admin" });
    } else {
      login({ userRole: "mlx-admin" });
    }
    if (isGetStarted) {
      if (onLogin) onLogin("step-2");
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div className="login-page-container d-flex flex-row">
      <img src={bgImage} style={{ width: "50%", height: "100vh" }} alt="" />
      <div
        style={{
          flexGrow: "1",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="" style={{ width: "280px" }}>
          <div className="">
            <img src={micrologx_logo} alt="logo" />
          </div>
          <div className="p-card-body">
            <form onSubmit={handleLogin}>
              <div className="p-field">
                <label htmlFor="username">Email</label>
                <InputText
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="p-field">
                <label htmlFor="password">Password</label>
                <InputText
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="p-field-checkbox">
                <Checkbox
                  inputId="rememberme"
                  name="rememberme"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.checked ? true : false)}
                />
                <label htmlFor="rememberme">Remember me</label>
              </div>
              <MlxButton type="submit" label="Login" className="" />
              <a href="#">Forgot Password?</a>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
