import React, { useState } from 'react';
import { Button, ButtonProps } from 'primereact/button';

const MlxButton: React.FC<ButtonProps> = (props: ButtonProps) => {

    return (
        <Button className='testName' {...props}></Button>
    )
}

export default MlxButton;