import React from "react";
import "./styles/sidebar.css";
import { NavLink } from "react-router-dom";
import HeaderComponent from "../components/header/HeaderComponent";
import ".././styles/common.css";
import { useAuth } from "../context/AuthContext";

const withSidebar = (WrappedComponent: React.FC) => {
  return (props: any) => {
    const { user } = useAuth();

    return (
      <div className="wrapper">
        <HeaderComponent />
        <div className="page-container d-flex flex-row">
          <div className="sidebar-nav">
            <div className="sidebar-item">
              <NavLink
                to={
                  user?.userRole === "mlx-admin"
                    ? "/dashboard"
                    : "/org-admin/dashboard"
                }
                className="nav-link"
                onClick={() => console.log("dashboard")}
                title="Dashboard"
                tabIndex={1}
              >
                <i
                  className="pi pi-user"
                  style={{ color: "#708090", margin: "10px" }}
                ></i>
                Dashboard
              </NavLink>
            </div>
            {user?.userRole === "mlx-admin" ? (
              <div className="sidebar-item">
                <NavLink
                  to="/manage-organization"
                  className="nav-link"
                  onClick={() => console.log("Org Management")}
                  title="Org Management"
                  tabIndex={1}
                >
                  <i
                    className="pi pi-user"
                    style={{ color: "#708090", margin: "10px" }}
                  ></i>
                  Org Management
                </NavLink>
              </div>
            ) : (
              <></>
            )}
            <div className="sidebar-item">
              <NavLink
                to={
                  user?.userRole === "mlx-admin"
                    ? "/manage-venue"
                    : "/org-admin/manage-venue"
                }
                className="nav-link"
                onClick={() => console.log("Venue Management")}
                title="Venue Management"
                tabIndex={1}
              >
                <i
                  className="pi pi-user"
                  style={{ color: "#708090", margin: "10px" }}
                ></i>
                Venue Management
              </NavLink>
            </div>
            {user?.userRole === "mlx-admin" ? (
              <>
                <div className="sidebar-item">
                  <NavLink
                    to="#"
                    className="nav-link"
                    onClick={() => console.log("User Management")}
                    title="User Management"
                    tabIndex={1}
                  >
                    <i
                      className="pi pi-user"
                      style={{ color: "#708090", margin: "10px" }}
                    ></i>
                    User Management
                  </NavLink>
                </div>
                <div className="sidebar-item">
                  <NavLink
                    to="#"
                    className="nav-link"
                    onClick={() => console.log("System Configuration")}
                    title="System Configuration"
                    tabIndex={1}
                  >
                    <i
                      className="pi pi-user"
                      style={{ color: "#708090", margin: "10px" }}
                    ></i>
                    System Configuration
                  </NavLink>
                </div>
                <div className="sidebar-item">
                  <NavLink
                    to="#"
                    className="nav-link"
                    onClick={() => console.log("Monitoring")}
                    title="Monitoring"
                    tabIndex={1}
                  >
                    <i
                      className="pi pi-user"
                      style={{ color: "#708090", margin: "10px" }}
                    ></i>
                    Monitoring
                  </NavLink>
                </div>
                <div className="sidebar-item">
                  <NavLink
                    to="#"
                    className="nav-link"
                    onClick={() => console.log("Reports")}
                    title="Reports"
                    tabIndex={1}
                  >
                    <i
                      className="pi pi-user"
                      style={{ color: "#708090", margin: "10px" }}
                    ></i>
                    Reports
                  </NavLink>
                </div>
                <div className="sidebar-item">
                  <NavLink
                    to="#"
                    className="nav-link"
                    onClick={() => console.log("Support")}
                    title="Support"
                    tabIndex={1}
                  >
                    <i
                      className="pi pi-user"
                      style={{ color: "#708090", margin: "10px" }}
                    ></i>
                    Support
                  </NavLink>
                </div>
              </>
            ) : (
              <></>
            )}
            {user?.userRole === "org-admin" ? (
              <>
                <div className="sidebar-item">
                  <NavLink
                    to="/org-admin/analytics-reporting"
                    className="nav-link"
                    onClick={() => console.log("Analytics and Reporting")}
                    title="Analytics and Reporting"
                    tabIndex={1}
                  >
                    <i
                      className="pi pi-user"
                      style={{ color: "#708090", margin: "10px" }}
                    ></i>
                    Analytics and Reporting
                  </NavLink>
                </div>
                <div className="sidebar-item">
                  <NavLink
                    to="#"
                    className="nav-link"
                    onClick={() => console.log("Config Settings")}
                    title="Config Settings"
                    tabIndex={1}
                  >
                    <i
                      className="pi pi-user"
                      style={{ color: "#708090", margin: "10px" }}
                    ></i>
                    Config Settings
                  </NavLink>
                </div>
                <div className="sidebar-item">
                  <NavLink
                    to="#"
                    className="nav-link"
                    onClick={() => console.log("Profile Settings")}
                    title="Profile Settings"
                    tabIndex={1}
                  >
                    <i
                      className="pi pi-user"
                      style={{ color: "#708090", margin: "10px" }}
                    ></i>
                    Profile Settings
                  </NavLink>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="sidebar-item">
              <NavLink
                to="#"
                className="nav-link"
                onClick={() => console.log("Help")}
                title="Help"
                tabIndex={1}
              >
                <i
                  className="pi pi-user"
                  style={{ color: "#708090", margin: "10px" }}
                ></i>
                Help
              </NavLink>
            </div>
          </div>
          <div className="wrapped-component-container">
            <WrappedComponent />
          </div>
        </div>
      </div>
    );
  };
};

export default withSidebar;
