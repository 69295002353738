import React from "react";
import withSidebar from "../../hoc/withSideBar";
import comingSoon from "../../assets/images/coming-soon.png";

function Dashboard() {
  return (
    <div className="page-title d-flex flex-column">
      Dashboard
      <div className="d-flex justify-content-center">
        <img
          style={{ height: "500px", width: "500px" }}
          src={comingSoon}
          alt=""
        />
      </div>
    </div>
  );
}

export default withSidebar(Dashboard);
