import React, { useState } from "react";
import withSidebar from "../../../hoc/withSideBar";
import MlxInputSwitch from "../../../components/common/MlxInputSwitch";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import MlxButton from "../../../components/common/MlxButton";
import AddVenueSidebar from "./components/AddVenueSidebar";

function OrgVenueManagement() {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const [tableData, setTableData] = useState([
    {
      "Venue Name": "Garibaldi",
      Email: "garibaldi@gmail.com",
      Phone: "+1-202-555-0176",
      Location: "Fort Worth, Texas",
      "Zip Code": "80202",
      "Sub Domain": "garibaldi.micrologx.inc",
      Status: "Active",
    },
    {
      "Venue Name": "Tenantl",
      Email: "tenantl@gmail.com",
      Phone: "+1-202-555-0176",
      Location: "Fort Worth, Texas",
      "Zip Code": "80202",
      "Sub Domain": "tenantl.micrologx.inc",
      Status: "Active",
    },
    {
      "Venue Name": "Tenant2",
      Email: "tenant2@gmail.com",
      Phone: "+1-202-555-0176",
      Location: "Fort Worth, Texas",
      "Zip Code": "80202",
      "Sub Domain": "tenant2.micrologx.inc",
      Status: "Active",
    },
    {
      "Venue Name": "Tenant3",
      Email: "tenant3@gmail.com",
      Phone: "+1-202-555-0176",
      Location: "Fort Worth, Texas",
      "Zip Code": "80202",
      "Sub Domain": "tenant3.micrologx.inc",
      Status: "Active",
    },
    {
      "Venue Name": "Tenant4",
      Email: "tenant4@gmail.com",
      Phone: "+1-202-555-0176",
      Location: "Fort Worth, Texas",
      "Zip Code": "80202",
      "Sub Domain": "tenant4.micrologx.inc",
      Status: "Active",
    },
  ]);

  const statusTemplate = (tempData: any) => {
    return (
      <>
        {tempData.Status}
        <MlxInputSwitch checked={true} />
      </>
    );
  };

  const editButton = (tempData: any) => {
    return <i className="pi pi-pencil" />;
  };

  return (
    <>
      <AddVenueSidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible}/>
      <div className="page-title">Manage Venue</div>
      <div className="org-table-container">
        <div className="org-table-header d-flex justify-content-between">
          <div>Venue List</div>
          <div>
            <MlxButton label="Add New Venue +" style={{ height:"45px", marginRight:"10px" }} onClick={()=>setSidebarVisible(true)}></MlxButton>
            <InputText placeholder="Search" />
          </div>
        </div>
        <DataTable className="org-mngmt-table" value={tableData}>
          <Column field="Venue Name" header="Venue Name"></Column>
          <Column field="Email" header="Email"></Column>
          <Column field="Phone" header="Phone"></Column>
          <Column field="Location" header="Location"></Column>
          <Column field="Zip Code" header="Zip Code"></Column>
          <Column field="Sub Domain" header="Sub Domain"></Column>
          <Column field="Status" header="Status" body={statusTemplate}></Column>
          <Column
            field="edit"
            body={() => (
              <i
                className="pi pi-pencil edit-row"
                onClick={() => console.log("edit clicked")}
              />
            )}
          ></Column>
          <Column
            field="delete"
            body={() => (
              <i
                className="pi pi-trash delete-row"
                onClick={() => console.log("delete clicked")}
              />
            )}
          ></Column>
        </DataTable>
      </div>
    </>
  );
}

export default withSidebar(OrgVenueManagement);
