import React from 'react'
import withSidebar from '../../../hoc/withSideBar'
import comingSoon from '../../../assets/images/coming-soon.png'

function OrgDashboard() {
  return (
    <div className='page-title d-flex flex-column'>
      Org Admin Dashboard
    <div className="d-flex justify-content-center">
        <img
          style={{ height: "500px", width: "500px" }}
          src={comingSoon}
          alt=""
        />
      </div>
    </div>
    
  )
}

export default withSidebar(OrgDashboard)